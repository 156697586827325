<template>
  <!--    <b-sidebar-->
  <!--            id="add-new-user-sidebar"-->
  <!--            :visible="isSidebarOpen"-->
  <!--            bg-variant="white"-->
  <!--            sidebar-class="sidebar-lg"-->
  <!--            shadow-->
  <!--            backdrop-->
  <!--            no-header-->
  <!--            right-->
  <!--            @change="(visible) => { if(!visible) resetForm();}"-->
  <!--            @shown="initData()"-->

  <!--    >-->
  <!--        <template #default="{ hide }">-->
  <!-- Header -->
  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'contacts' }">{{ $t('label_contacts') }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ action == 'editing' ? $t('label_edit') : $t('label_add_client') }}
        </b-breadcrumb-item>
      </div>
    </div>

    <b-col cols="12" md="12">
      <b-card
          class="mb-0"
          no-body
      >

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ action == 'editing' ? $t('label_edit') : $t('label_add_client') }}
          </h5>

        </div>

        <!-- Form -->
        <validation-observer
            :ref="PREFIX + '_FORM'"
            #default="{ handleSubmit, invalid }"
            class="p-2"
            tag="form"
        >
          <validation-provider
              #default="validationProps"
              :name="$t('label_choose_the_type_of_customer')"
              rules="required"
          >
            <b-form-group
                :label="$t('label_choose_the_type_of_customer')"
                :state="getValidationState(validationProps)"
                class="required"
            >
              <!--                  @input="showOrHidePesselNip($event)"-->
              <v-select
                  v-model="itemData.customer_type_id"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customer_types"
                  :placeholder="$t('label_select')"
                  :reduce="val => val.id"
                  input-id="customer_type_id"
                  label="name"
              >
                <template v-slot:option="option">
                  <span class="">{{ option.name }}</span>
                </template>
                <template v-slot:selected-option="option">
                  <!--<span :class="option.icon"></span>-->
                  <span class="">{{ option.name }}</span>
                </template>

              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template
              v-if="itemData.customer_type_id && customer_types.findWhere('id', itemData.customer_type_id).name_alias == 'firma'">
            <validation-provider

                #default="validationContext"
                :name="$t('label_company_name')"
                rules="required"
                slim
                tag="div"
            >
              <b-form-group
                  :label="$t('label_company_name')"
                  class="required"

              >
                <b-form-input

                    v-model="itemData.firm_name"
                    :placeholder="$t('label_company_name')"
                    :state="getValidationState(validationContext)"
                    autofocus
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
                #default="validationContext"
                :name="$t('label_nip')"
                rules="required"
                slim
            >
              <b-form-group
                  :label="$t('label_nip')"
                  class="required"
              >
                <b-form-input

                    v-model="itemData.nip"
                    :placeholder="$t('label_nip')"
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <validation-provider
              #default="validationContext"
              :name="$t('label_pesel')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_pesel')"
            >
              <b-form-input

                  v-model="itemData.pesel"
                  :placeholder="$t('label_pesel')"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_first_name')"
              rules="required"
              slim
              tag="div"
          >
            <b-form-group
                :label="$t('label_first_name')"
                class="required"

            >
              <b-form-input
                  v-model="itemData.first_name"
                  :placeholder="$t('label_first_name')"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
              #default="validationContext"
              :name="$t('label_last_name')"
              rules="required"
              slim
          >
            <b-form-group
                :label="$t('label_last_name')"
                class="required"
            >
              <b-form-input
                  v-model="itemData.last_name"
                  :placeholder="$t('label_last_name')"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_phone')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_phone')"
            >
              <b-form-input
                  :placeholder="$t('label_phone')"
                  v-model="itemData.phone"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_private_phone')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_private_phone')"

            >
              <b-form-input
                  :placeholder="$t('label_private_phone')"
                  v-model="itemData.phone_private"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="validationContext"
              :name="$t('label_email_address')"
              rules="required|email"
              slim
          >
            <b-form-group
                :label="$t('label_email_address')"
                class="required"
            >
              <b-form-input
                  :placeholder="$t('label_email_address')"
                  v-model="itemData.email"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_private_email')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_private_email')"

            >
              <b-form-input
                  :placeholder="$t('label_private_email')"
                  v-model="itemData.email_private"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_swift_number')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_swift_number')"
            >
              <b-form-input
                  :placeholder="$t('label_swift_number')"
                  v-model="itemData.swift_number"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider

              #default="validationProps"
              :name="$t('label_address_from_agreement')"
              rules="required"
              slim
          >
            <b-form-group
                :label="$t('label_address_from_agreement')"
                label-for="n-address_agreement"
                class="required"
            >
              <b-form-textarea
                  id="n-address_agreement"
                  :placeholder="$t('label_address_from_agreement')"
                  :state="getValidationState(validationProps)"
                  v-model="itemData.address_agreement"
              ></b-form-textarea>

              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider

              #default="validationProps"
              :name="$t('label_correspondence_address')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_correspondence_address')"
                label-for="n-address_correspondence"
            >
              <b-form-textarea
                  id="n-address_correspondence"
                  :placeholder="$t('label_correspondence_address')"
                  :state="getValidationState(validationProps)"
                  v-model="itemData.address_correspondence"
              ></b-form-textarea>

              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deal_type -->
          <validation-provider
              #default="validationContext"
              :name="$t('label_deal_type')"
              rules="required"
              slim
          >
            <b-form-group
                :label="$t('label_deal_type')"
                :state="getValidationState(validationContext)"
                class="required"
            >
              <v-select
                  v-model="itemData.deal_type_id"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="item_deal_types"
                  :placeholder="$t('label_select')"
                  :reduce="val => val.id"
                  label="name"

              >
                <template v-slot:option="option">
                  <span class="">{{ option.name }}</span>
                </template>
                <template v-slot:selected-option="option">
                  <span class="">{{ option.name }}</span>
                </template>
                <template v-slot:no-options="option">
                  {{ $t('label_no_items') }}
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Consultant -->
          <validation-provider

              #default="validationProps"
              :name="$t('label_consultant_leading_case')"
              rules="required"
              slim
          >
            <b-form-group
                :label="$t('label_consultant_leading_case')"
                class="required"
            >

              <infinity-scroll
                  v-model="itemData.consultant_id"
                  :default-selection="chosenConsultant"
                  :placeholder="$t('label_select')"
                  selected-prop="id"
                  url="/consultant"
              >
                <template #label="{item}">{{ item.name }}</template>

              </infinity-scroll>

              <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>


          <!-- Agent -->
          <validation-provider

              #default="validationProps"
              :name="$t('label_recommended')"
              rules=""
              slim
          >
            <b-form-group :label="$t('label_recommended')"
            >

              <infinity-scroll
                  v-model="itemData.agent_id"
                  :default-selection="chosenAgent"
                  :placeholder="$t('label_select')"
                  selected-prop="id"
                  url="/agent"
              >
                <template #label="{item}">{{ item.name }}</template>

              </infinity-scroll>

              <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('label_related_users')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_related_users')"
                :state="getValidationState(validationContext)"
            >

              <infinity-scroll
                  v-model="itemData.users"
                  :default-selection="chosenUsers"
                  :disabled="auth_user.role == 'consultant'"
                  :multiple="true"
                  :placeholder="$t('label_select')"
                  :query-params="auth_user.role == 'consultant'? {type:'contact_related_users', role:'consultant'} : {}"
                  :url="auth_user.role == 'consultant'? '/role_access_users/1' : 'contact_allowed_users'"
                  selected-prop="id"
              >
                <template #label="{item}">{{ item.name }}</template>

              </infinity-scroll>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              v-if="action == 'editing' && $can('edit', 'deal.tab_details') && $can('edit', 'contact.related_agreements' )"
              #default="validationContext"
              :name="$t('label_choose_related_deal')"
              rules=""
              slim
          >
            <b-form-group
                :label="$t('label_choose_related_deal')"

                :state="getValidationState(validationContext)"
            >

              <infinity-scroll
                  v-model="itemData.deals"
                  :default-selection="chosenDeals"
                  :multiple="true"
                  :placeholder="$t('label_select')"
                  selected-prop="deal_id"
                  url="/deal"
              >
                <template #label="{item}">{{ $t('label_deal') + ' ' + item.deal_number }}</template>

              </infinity-scroll>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mr-2"
                      type="submit"
                      variant="primary"
                      @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
            >
              {{ action == 'editing' ? $t('label_save') : $t('label_add') }}
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.go(-1)"
            >
              {{ $t('label_cancel') }}

            </b-button>
          </div>

        </validation-observer>
      </b-card>
    </b-col>
  </div>
  <!--        </template>-->
  <!--    </b-sidebar>-->
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'

import {ValidationProvider, ValidationObserver} from 'vee-validate'

import infinityScroll from '@/views/components/infinityScroll'

import vSelect from 'vue-select'

// import { CONTACT_PREFIX as PREFIX} from './../pagesHelper'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    infinityScroll
  },

  // model: {
  //     prop: 'isSidebarOpen',
  //     event: 'update:is-sidebar-open',
  // },
  // props: ['isSidebarOpen','editedItem'],
  data() {
    return {
      PREFIX: 'contacts',

      action: 'adding',

      blankItemData: {
        first_name: '',
        last_name: '',
        firm_name: '',
        email: '',
        email_private: '',
        phone: '',
        phone_private: '',
        nip: '',
        pesel: '',
        customer_type_id: '',
        address_agreement: '',
        swift_number: '',
        address_correspondence: '',
        consultant_id: null,
        agent_id: null,
        deal_type_id: null,
        deals: [],
        users: []
      },
      itemData: {},

      item_deal_types: [],
      // item_consultants: [],
      // item_agents: [],
      // item_related_deals: [],

      chosenConsultant: false,
      chosenAgent: false,
      chosenDeals: [],
      chosenUsers: [],
      auth_user: this.authUser(),
      customer_types: [],
      edited_contact_id: false,

    }
  },

  methods: {

    initData() {

      if (this.edited_contact_id) {

        this.action = 'editing';

        this.async('get', '/' + this.PREFIX + '/' + this.edited_contact_id, {}, function (resp) {
          let item = Object.assign({}, this.blankItemData);
          item.id = this.edited_contact_id;
          for (let prop in item) {
            if (this.editedItem.hasOwnProperty(prop)) {
              item[prop] = this.editedItem[prop];
            }
          }
          this.chosenDeals = this.editedItem.deals.map((el) => {
            el.deal_number = el.number;
            el.deal_id = el.id;
            return el;
          });
          item.deals = this.editedItem.deals.pluck('id');

          this.chosenUsers = this.editedItem.users;
          item.users = this.editedItem.users.pluck('id');

          this.itemData = item;

          this.chosenConsultant = this.editedItem.consultant;

          this.chosenAgent = this.editedItem.agent;

        });

      } else {
        this.action = 'adding';
        this.itemData = Object.assign({}, this.blankItemData);

        if (this.auth_user.role == 'consultant') {
          this.async('get', '/role_access_users/1', {
            params: {
              role: 'consultant',
              type: 'contact_related_users'
            }
          }, function (res) {
            this.chosenUsers = res.data;
            this.itemData.users = this.chosenUsers.pluck('id');
          });
        }
      }

    },
    // resetForm() {
    //     this.itemData = Object.assign({}, this.blankItemData);
    //     this.$refs[this.PREFIX + '_FORM'].reset();
    //     this.$emit('update:is-sidebar-open', false)
    // },

    onSubmit() {
      let self = this;

      if (this.action == 'editing') {
        this.$store.dispatch('app/editContact', {itemData: this.itemData, vm: this})
            .then((resp) => {
              this.$router.push({name: (this.MODULE_PREFIX) + '-details', params: {id: resp.data.item.id}});
              // self.$emit('refetch-data');
              // self.$emit('item-updated', this.itemData);
              // self.$emit('update:is-sidebar-open', false);
            })
      } else {
        this.$store.dispatch('app/addContact', {itemData: this.itemData, vm: this})
            .then((resp) => {
              this.$router.push({name: this.MODULE_PREFIX + '-details', params: {id: resp.data.item.id}});
              // self.$emit('item-added', r.data.item);
              // self.$emit('refetch-data')
              // self.$emit('update:is-sidebar-open', false)
            })
      }
    },

  },
  created() {
    this.async('get', '/select_options', {params: {options: ['deal_subjects', 'customer_types']}}, function (resp) {
      this.item_deal_types = resp.data.deal_subjects;
      this.customer_types = resp.data.customer_types;
      this.blankItemData.customer_type_id = this.itemData.customer_type_id = this.customer_types.findWhere('name_alias', 'firma').id;
    });
    this.edited_contact_id = this.$router.currentRoute.params.id;

    this.initData();

  }
}
</script>


